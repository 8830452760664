










































































import Vue from 'vue'
import utils from '../../utils'
import { LCircle, LFeatureGroup, LPolygon } from 'vue2-leaflet'
import { getTheme } from '@/components/Map/utils/themes'
import { mapGetters } from 'vuex'
import {
  EDetectionState,
  IDetectionOnly,
  IDetectionContribution,
  CONTROLLER_CLASSIFICATION
} from '@/store/modules/detection/types'
import { displayRadiatingSector } from '@/components/Map/Detection/radiatingSector'
import { COLOR_FRIEND, COLOR_THREAT } from '@/store/modules/detection/types'
import { rad2deg, deg2rad } from '@/utils/utils'

const props = {
  contributor: {
    type: Object as () => IDetectionContribution,
    default: () => {}
  },
  visible: {
    type: Boolean,
    default: true
  },
  detection: {
    type: Object as () => IDetectionOnly,
    default: () => {}
  },
  activeBeam: {
    type: String
  }
}

export default Vue.extend({
  name: 'RfSector',
  inject: ['getSentryLocationOverride'],
  components: {
    LFeatureGroup,
    LPolygon,
    LCircle
  },
  props,
  mounted() {
    this.$nextTick(() => {
      displayRadiatingSector(this.detectionColorFill)
    })
  },
  computed: {
    ...mapGetters('rf_sensors', ['rfSensorById', 'allRfSensorsInSentry']),
    ...mapGetters('sentries', ['sentriesSet']),
    overrideSentryLocation() {
      return this.getSentryLocationOverride()
    },
    origin() {
      if (this.overrideSentryLocation) {
        return {
          latitude: this.overrideSentryLocation.latitude,
          longitude: this.overrideSentryLocation.longitude,
          altitude: this.overrideSentryLocation.altitude,
          direction: this.overrideSentryLocation.direction
        }
      }
      return {
        latitude: this.sensor.latitude,
        longitude: this.sensor.longitude,
        altitude: this.sensor.altitude,
        direction: this.sensor.direction
      }
    },
    themeName() {
      return this.$store.state.theme
    },
    theme() {
      return getTheme(this.themeName)
    },
    fhssDashArray() {
      if (this.detection.classification === CONTROLLER_CLASSIFICATION)
        return this?.theme?.fhss?.dashArray || ''
      return ''
    },
    detectionColor() {
      switch (this.detection.state) {
        case EDetectionState.Whitelisted:
          return COLOR_FRIEND
        default:
          return COLOR_THREAT
      }
    },
    withinExtendedRange() {
      return (
        Math.abs(this.angleDifference) < 70 &&
        !this.anyRfSensorOverlap(this.positiveAngle ? 'right' : 'left')
      )
    },
    detectionColorFill() {
      switch (this.detection.state) {
        case EDetectionState.Whitelisted:
          return COLOR_FRIEND
        default:
          return COLOR_THREAT
      }
    },
    key() {
      return `${this.detection.target_id}:${this.contributor.sensor_id}`
    },
    isSelected() {
      return this.activeBeam === this.key
    },
    fillOpacity() {
      return this.isSelected
        ? this.theme?.detection?.opacity?.selected || 0.7
        : this.theme?.detection?.opacity?.unselected || 0.4
    },
    weight() {
      return this.isSelected
        ? this.theme?.detection?.lineWeight?.selected || 2.0
        : this.theme?.detection?.lineWeight?.unselected || 0.3
    },
    sensor() {
      return this.rfSensorById(this.contributor.sensor_id) || {}
    },
    isOutside() {
      return this.angleDifference >= 45 || this.angleDifference <= -45
    },
    angleDifference() {
      return this.aoaDegrees - this.origin.direction
    },
    aoaDegrees() {
      return rad2deg(this.aoa)
    },
    range() {
      const { reach } = this.sensor || {}
      const { direction = 0, latitude, longitude } = this.origin || {}
      return reach
        ? utils.createRange({
          reach,
          direction,
          vertex: [latitude, longitude]
        })
        : []
    },
    radDirection() {
      return deg2rad(this.sensor.direction || 0)
    },
    aoa() {
      return (this.contributor.aoa + this.radDirection) % 360
    },
    sectorPolygon() {
      return isLine => {
        const { reach } = this.sensor
        const { latitude, longitude } = this.origin
        return latitude
          ? utils.makeArcPathFromRfDetection({
            azimuth_std_rad: this.contributor.aoa_error,
            azimuth_rad: this.aoa,
            vertex: { lat: latitude, lng: longitude },
            reach,
            show_sector_as_line: isLine
          })
          : []
      }
    },
    positiveAngle() {
      return Math.sign(this.angleDifference) > 0
    },
    outsideDetection() {
      return angle => {
        const [min, max] = this.positiveAngle
          ? [45, 45 + angle]
          : [-45 - angle, -45]
        const { reach } = this.sensor
        const { latitude, longitude, direction } = this.origin
        return utils.createRange({
          reach,
          direction,
          min,
          max,
          vertex: [latitude, longitude]
        })
      }
    },
    otherRfSensors() {
      return this.allRfSensorsInSentry(
        this.sentriesSet[this.sensor.sentry_id]
      ).filter(sensor => sensor.id !== this.sensor.id)
    }
  },
  methods: {
    onSelect() {
      this.$emit('selectSfRfBeam', this.detection.target_id, this.key)
    },
    anyRfSensorOverlap(side = 'left') {
      return this.otherRfSensors.some(rfsensor => {
        const otherDirection = utils.circularBounds(rfsensor.direction, [
          0,
          360
        ])
        const sensorNeighbourDirection = utils.circularBounds(
          this.origin.direction + (side === 'left' ? -90 : 90),
          [0, 360]
        )
        return sensorNeighbourDirection === otherDirection
      })
    }
  }
})
